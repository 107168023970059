@font-face {
  font-display: swap;
  font-family: 'Averta Bold';
  font-weight: bold;
  src: url('./AvertaPE Bold.otf') format('opentype');
}

@font-face {
  font-display: swap;
  font-family: 'Averta';
  font-weight: normal;
  src: url('./AvertaPE.otf') format('opentype');
}

@font-face {
  font-display: swap;
  font-family: 'Averta Light';
  font-weight: 300;
  src: url('./AvertaPE Light.otf') format('opentype');
}
