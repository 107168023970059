@import url('./assets/fonts/Averta.css');

body {
  margin: 0;
  font-family: 'Averta Light' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input.hs-button.primary.large {
  background: #f8b100 !important;
}
.flip-card {
  height: 85px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute !important;
  width: 100%;
  height: 100%;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
  -moz-backface-visibility: hidden; /* Firefox */
}

.flip-card-front {
  z-index: -1;
}
/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
  -moz-transform: rotateY(180deg); /* Firefox */
}

.rot180 {
  transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg); /* Firefox */
}

#searchBar::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #f8b100;
  opacity: 1;
}
#searchBar::-moz-placeholder {
  /* Firefox 19+ */
  color: #f8b100;
  opacity: 1;
}
#searchBar:-ms-input-placeholder {
  /* IE 10+ */
  color: #f8b100;
  opacity: 1;
}
#searchBar:-moz-placeholder {
  /* Firefox 18- */
  color: #f8b100;
  opacity: 1;
}

.DashboardItemText {
  font-family: Averta;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  color: #9f9f9f;
}

.MuiDrawer-docked {
  position: relative;
}

.MuiSnackbar-anchorOriginBottomCenter {
  bottom: 20% !important;
}

.scroll-bar {
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll-bar::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.scroll-bar::-webkit-scrollbar-track {
  background-color: transparent;
}
/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #dadce4;
  border-radius: 5px;
}
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.layout {
  min-width: auto !important ;
}

main {
  display: block !important;
}

.css-at12u2-loadingIndicator {
  display: none !important;
}

.css-1wa3eu0-placeholder {
  color: hsl(0deg 2% 75%) !important;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: #d6d6d6 !important;
}

button:hover {
  opacity: 0.85;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #f8b100 !important;
}

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  height: 300px !important;
}

.rdw-image-imagewrapper > img {
  width: 100% !important;
  height: 100% !important;
}

.__se__float-center {
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
canvas.mapboxgl-canvas {
  width: 100% !important;
  height: 100% !important;
  position: fixed;
}
@keyframes leave {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* .draggable {
  cursor: move;
  user-select: none;
  font-size: 13px !important;
  text-align: center;
} */
/* .placeholder {
  background-color: #edf2f7;
  border: 1px dashed #cbd5e0;
  font-size: 13px !important;
  text-align: center;
} */
 #table {
  /* border: 1px solid yellow !important; */
 }
.clone-list {
  display: flex;
  font-size: 13px !important;
  text-align: center;
}
.clone-table {
  border-collapse: collapse;
  border: none;
  font-size: 13px !important;
  text-align: center;

  
}
.clone-table tr {
  height: 60px !important;
  margin: 0 -2px;
}
.clone-table th {
  border: 1px solid #ff0000;

  font-size: 12px !important;
  text-align: center;
  display: 'flex';
  font-size: 13px !important;
  font-weight: 600;
  /* line-height: 1; */
  color: '#43424b';
  text-align: 'center';
  align-items: 'center';

  height: 60px !important;
  display: flex;
  min-width: 90px;
  align-items: center;
  border-left: 2px solid #00b7ff;
  border-right: 2px solid #5900ff;
  justify-content: space-evenly;
}
.clone-table td {
  /* border-left: 1px solid #b3ff00;
  border: 1px solid #09ff00; */
  font-size: 12px !important;
  text-align: center;
  display: 'flex';
  font-size: 12px !important;
  /* line-height: 1; */
  color: '#43424b';
  text-align: 'center';
  align-items: 'center';
  height: 60px !important;
  display: flex;
  min-width: 90px;
  align-items: center;
  /* border-left: 1px solid #ff9696;
  border-right: 1px solid #ff8989; */
  border: none;
  margin: 0px 0px;
  height: 56px !important;
  justify-content: space-evenly;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.2);

}

/* .dragging {
  background: #fff;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  z-index: 999;
  font-size: 13px !important;
  text-align: center;
} */
